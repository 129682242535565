import React, {useRef, useState} from "react"
import { StaticImage } from 'gatsby-plugin-image';
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import HeadData from '../data/HeadData';

const AboutPage = (props) => {
    return (
        <div>
            <HeadData title='About Disc Golf' />
            <NavBar key="navbar" path={props.path}/>
            <div className="flex flex-row flex-wrap lg:flex-col bg-white items-center justify-center pt-16 lg:pt-32">
                <div className="w-full max-w-4xl px-10 mt-5 mb-10">
                    <div className="relative animate-drop2 transform scale-0 border-b-4 border-site-blue mb-8">
                        <h1 className="text-2xl md:text-4xl font-medium text-center font-sans mb-3">
                            About Laem Sor Beach Disc Golf And Acoustic Bar
                        </h1>
                    </div>

                    <StaticImage className="w-72 h-48 md:w-96 md:h-60 sm:float-right m-2 mb-5" alt='about'
                        src="../images/throw1.jpg"/>
                    <p className="font-sans mt-2 text-lg">Disc golf is played much like traditional golf. Instead of a ball and clubs, however, players use a flying disc. The sport was formalized in the 1970's, and shares with "ball golf" the object of completing each hole in the fewest strokes (or, in the case of disc golf, fewest throws). Each golf disc has a specific design, flight characteristic and turning tendency which gives each individual disc a sort of personality but generally speaking, each disc can be classified as a putter, midrange, fairway or distance driver.</p>
                </div>
                <div className="w-full max-w-4xl px-10 mb-5">
                    <p className="font-sans mt-2 text-lg">Depending on the landscape and terrain, a disc is then selected and thrown from a tee area to a ‘hole” which is a chest high target of metal chains suspended over an elevated metal basket. As a player progresses down the fairway, he or she must make each consecutive throw from the spot where the previous throw has landed. The trees, shrubs, and terrain changes located in and around the fairways provide challenging obstacles for the golfer. Distance drivers are designed more aerodynamically to fly, glide and cut through the air and even maybe skip a little further for distance. Midrange discs are used to approach and perhaps even snipe the target from afar. Finally, the “putter” is usually used at close range to strike, splash into the chains, fall into the basket and the hole is completed. Otherwise, the disc can get spit out of the chains, skip and fly off the top of the basket, get stopped short by the rim like a brickshot and maybe roll away even further.</p>
                </div>
                <div className="w-full max-w-4xl px-10 mb-5">
                    <p className="font-sans mt-2 text-lg">Regardless, disc golf shares the same joys and frustrations of traditional golf, whether it’s making a hole in one, sinking a long putt or hitting a tree halfway down the fairway. Disc golf and is an ever challenging exercise in balance, agility and concentration that is becoming the world’s favorite pastime for having fun with friends.</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AboutPage;